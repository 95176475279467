import React from 'react'
import ErrorArea from '~containers/404'

const NotFoundPage = () => {
    return (
        <ErrorArea/>
    )
}

export default NotFoundPage;
